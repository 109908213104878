// breackpoints mobile first and media query
$screen-sm-min: 768px;
$screen-md-min: 1024px;
$screen-lg-min: 1280px;
$screen-xl-min: 1440px;
$screen-xxl-min: 1920px;

@mixin media-sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

@mixin media-md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

@mixin media-lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

@mixin media-xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

@mixin media-xxl {
  @media (min-width: #{$screen-xxl-min}) {
    @content;
  }
}

// overflowY
@mixin overflowY {
  overflow-y: auto;
  // overflow-y: overlay;
  -webkit-overflow-scrolling: auto !important;
  //-webkit-overflow-scrolling: touch;
}

// text overflow
@mixin textOverflow {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

// code and bold inside block of text from translations
@mixin codeAndBold {
  code {
    font-family: monospace !important;
    color: var(--text);
    background: var(--bg-d5);
    padding: 0 0.25rem;
  }
  b,
  strong {
    font-weight: var(--semi-bold);
  }
}

// message with illustration
@mixin wrapIllustration {
  min-height: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@mixin msgIllustration {
  width: 18rem;
  font-size: var(--font-lg);
  line-height: 1.5;
  color: var(--text);
  text-align: center;
}

@mixin descIllustration {
  width: 18rem;
  font-size: var(--font-sm);
  line-height: 1.5;
  color: var(--text-a40);
  text-align: center;
}

// input common styles used in input.scss, simpleSelect.scss
@mixin inputContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

@mixin inputLabelinrow {
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

@mixin inputWrapLabel {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-auto-rows: minmax(1.5rem, auto);
  grid-template-areas: "label helpTooltip";
  grid-column-gap: 0.25rem;

  .labelinrow & {
    grid-auto-rows: 2rem;
    align-items: center;
  }

  .labelHint & {
    grid-auto-rows: 1.5rem auto;
    grid-template-areas: "label helpTooltip" "hint hint";
  }

  .labelinrow .labelHint & {
    grid-auto-rows: 2rem auto;
    grid-template-areas: "label helpTooltip" "hint hint";
  }
}

@mixin inputLabel {
  grid-area: label;
  width: 100%;
  font-size: var(--font-sm);
  font-weight: var(--regular);
  line-height: 1.25rem;
  color: var(--text);

  .required & {
    &:after {
      content: "\002A";
      font-size: var(--font-base);
      color: var(--accent);
      margin: 0 0 0 0.125rem;
    }
  }

  .labelinrow & {
    line-height: 2rem;
  }

  .disabled & {
    opacity: 0.5;
  }

  .withErrors & {
    color: var(--red);
  }
}

@mixin inputHelpTooltip {
  grid-area: helpTooltip;
}

@mixin inputHint {
  grid-area: hint;
  font-size: var(--font-xs);
  font-weight: var(--regular);
  line-height: 1.125rem;
  color: var(--text-a40);
  padding: 0 0 0.25rem 0;
  @include codeAndBold;
}

@mixin inputWrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

@mixin input {
  width: 100%;
  height: 2.5rem;
  font-family: var(--font-family);
  font-size: var(--font-base);
  font-weight: var(--regular);
  color: var(--text);
  line-height: 2.5rem;
  background: var(--white);
  border: 1px solid var(--input-border);
  border-radius: 0.25rem;
  box-sizing: border-box;
  padding: 0 0.375rem;
  appearance: none;
  outline: none;
  transition: border 0.05s ease-in-out;

  @include media-md {
    height: 2rem;
    font-size: var(--font-sm);
    line-height: 2rem;
  }

  &:hover {
    border: 1px solid var(--input-border-hover);
  }

  &:focus {
    border: 1px solid var(--accent);
    outline: none !important;
  }

  &:-webkit-autofill {
    box-shadow: inset 0 0 0px 9999px var(--accent-l35);
  }

  &::placeholder {
    opacity: 0.5;
  }

  &:required {
    box-shadow: none !important;
    outline: none !important;
  }

  .disabled & {
    opacity: 0.5;
    background: var(--bg);
    &:hover {
      border: 1px solid var(--input-border);
    }
    &:focus {
      border: 1px solid var(--input-border);
    }
  }

  .withErrors & {
    border: 1px solid var(--red) !important;
  }
}

@mixin inputErrorMsg {
  font-size: var(--font-xs);
  color: var(--red);
  line-height: 1.25rem;
}

@mixin inputIcon {
  position: absolute;
  top: 0.75rem;
  right: 0.5rem;

  @include media-md {
    top: 0.5rem;
  }
  .disabled & {
    opacity: 0.5;
  }
}

// buttonIcon animation
@mixin hoverBtnIconAnimation {
  animation: hoverBtnIconAnimation 0.15s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

@mixin clickBtnIconAnimation {
  animation: clickBtnIconAnimation 0.3s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

@keyframes hoverBtnIconAnimation {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes clickBtnIconAnimation {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}
